import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/owenb/Documents/ems/ems-website/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`History Window`}</h1>
    <p>{`The history window will display all of the saved record.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/831f236261c4deb7ea12e5769857af96/ec605/history_nav.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "205.00000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAApABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwH/xAAXAQEBAQEAAAAAAAAAAAAAAAABAAID/9oADAMBAAIQAxAAAAG5qU3ictIVHTHTUCsucwSwAf/EAB4QAAIBBAMBAAAAAAAAAAAAAAABEQIDEhMUITEz/9oACAEBAAEFAtBoRxxsyKXKaZiylQNmRS5T9gXRd+dr0//EABgRAAMBAQAAAAAAAAAAAAAAAAIQEwBB/9oACAEDAQE/AZjpi+r/xAAYEQADAQEAAAAAAAAAAAAAAAACEBMAQf/aAAgBAgEBPwGhahPi/8QAGRAAAQUAAAAAAAAAAAAAAAAAAAEQMDFB/9oACAEBAAY/AizZVf8A/8QAHxAAAQMEAwEAAAAAAAAAAAAAAQAQESExQXFhgZGh/9oACAEBAAE/IabSfEcRHpbfDUuGA4yxAalOGCZoQIqkwtab/9oADAMBAAIAAwAAABAQNDxDz//EABgRAAIDAAAAAAAAAAAAAAAAABBhABEx/9oACAEDAQE/EExJvA//xAAYEQACAwAAAAAAAAAAAAAAAAAQYQARMf/aAAgBAgEBPxBkaa0P/8QAIRABAAMAAgEEAwAAAAAAAAAAAQARITFBsRBhgaGR4fD/2gAIAQEAAT8QTez+8CPwAJS3p77SrEHciyWZ0jItc1kQDa6/mDuifP7iqO7cqxB3LiwszpGRa5rJWcBXm9+5sLSWONeY7R3bng+Z916f/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "History Navigation",
            "title": "History Navigation",
            "src": "/static/831f236261c4deb7ea12e5769857af96/09b79/history_nav.jpg",
            "srcSet": ["/static/831f236261c4deb7ea12e5769857af96/59e87/history_nav.jpg 60w", "/static/831f236261c4deb7ea12e5769857af96/e439a/history_nav.jpg 120w", "/static/831f236261c4deb7ea12e5769857af96/09b79/history_nav.jpg 240w", "/static/831f236261c4deb7ea12e5769857af96/158ba/history_nav.jpg 360w", "/static/831f236261c4deb7ea12e5769857af96/7cc5e/history_nav.jpg 480w", "/static/831f236261c4deb7ea12e5769857af96/ec605/history_nav.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Overall Action Buttons`}</h3>
    <p>{`The action buttons are located on the top of history screen.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Filter Records Date
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "192px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/12f017b9eb0d2717aa66e3f529968932/8514f/filter.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "33.33333333333333%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABX0lEQVQoz12SyYrCQBRFa+MH+It+kguVuHIvLgRREURREUVXBhM1OE84ECuJRgPtbe4DpenAJa/qTedVldJa43a7feW6rog2ff/9Wusfz/NerutGYRhGAKL3+y3SWkdqNpvhcDhgt9thsVjgcrlgv9/Dtm2Mx2NMp1NYlgXGTSYT+W+3WzyfT/T7faRSKRSLRWSzWVyvV6hCoYByuYxqtSpyHEcKdLtd9Ho9NBoNSRwOh2i1Wuh0OjBNE/wSiQSUUojH44jFYhKnSDOfz4WIpOfz+UtLEvrW6zU2m4004/7xeITv+0in06jX69LAMAxppjhGrVYTAhJypMFggHw+L+tmsyk2E9vttiSNRiOEYYhkMolSqSTxtDmBOp1OWC6Xcm7sTkLa3GNxkq1WKzk/2qQl4ePxQKVSEcpcLodMJiP5irfoBwE8z0MQBHKrH5tjffR3TT9fAotS9/tdLom+X3Zd6JKMmLrfAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Sync Icon",
                "title": "Sync Icon",
                "src": "/static/12f017b9eb0d2717aa66e3f529968932/8514f/filter.png",
                "srcSet": ["/static/12f017b9eb0d2717aa66e3f529968932/56c36/filter.png 60w", "/static/12f017b9eb0d2717aa66e3f529968932/070ae/filter.png 120w", "/static/12f017b9eb0d2717aa66e3f529968932/8514f/filter.png 192w"],
                "sizes": "(max-width: 192px) 100vw, 192px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Opens date selection for filtering rescue times call time.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sync Cloud Data
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "46px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f219f42c7dccc8028da5bd279975a2a8/f15b2/sync.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "89.13043478260869%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACrklEQVQ4y4WUvUsrQRDAL/cQXqXh1Xb6NwhqMFbaCSLCQxAbSxsLhTRaWWgjaB3kWVgKwdrmiYJYyKsMWkg4QdC9nb2PGHJ3O/OYe7vh1MhbGO52due3M7M74zhmKKU+yrc+ui/XPo3CYgkASoX5iFKqYmTE6s2eUl8gAPRgBdA8APwGgFgpRQDAEhvd/EcbnvcN9eHhgb8HDPB9PwcppTIAyIIgoDAMyRxwQERFZ/55ygqjdI1y13iTEVEaxzECACqlUAiBnuelUsrMQHeNjWs5lmxhkwwLgkDHcax3dnbo4uICiQg3NjZwdHQUV1dXqdls5utSSoZOFh2yCbYnHPOmJEmStbU1WlhYwFarRTzm5ubIcRw6OTnBNE05HYmJ5NjYuu9CBoDvvu/f8+ZGo6Gr1WpuGEURdTod2tvbo8PDQ5qenibP8zAMQ22A92xbDNne0g8hRMDerK+v4/b2NodKQoj8crrdLrXbbarX6zkwCAI0Fxawrb3xInBICPHCkFqtpjc3NxER6enpiVNAd3d3NDExQcPDw3R0dMSH2Ry+KKWGisBe2FLKKw7v5uYmW1xcxOXlZTw9PaXz83MaHBzMc+g4DpbLZazX6xk/IQC4Kj6fd6Ukpazxc3h+fu4uLS3lxo1GA7e2tnowI/zf5QvrdDo1BlqGJedh397eltM0fTw7O6Px8fGkWq3S9fU1tVotXFlZwUqlQrOzszQzM5Ps7+9z/h593y8bYKn4DvN3JIRwfN+farfbb3zDiJhEUZRGUZQhYqa1TrXWidaatNZvYRhORVHkxHHsvqtpOxFC5NDX19cxIcQfW3q2DK3wmud5YwwLw9A1HvbtNL0XL6UcAICfAPBLKXUJAJfmn3UDHyrsc8cptKVPG78Qt9gY+o5+jdSUVMkk3f1fc/0LyD30NL8RWlkAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Sync Icon",
                "title": "Sync Icon",
                "src": "/static/f219f42c7dccc8028da5bd279975a2a8/f15b2/sync.png",
                "srcSet": ["/static/f219f42c7dccc8028da5bd279975a2a8/f15b2/sync.png 46w"],
                "sizes": "(max-width: 46px) 100vw, 46px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Only for premium Response Time Trackers. Sync rescue times with cloud storage of times.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Download csv
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "45px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2da93a0ed1ad4ae0fbbe44922f44625f/e68b9/download.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACh0lEQVQ4y4VUMU8qQRA+OaxI/Ak274XC//AaO01sXkFhzQ8wlmAjYgyJMf4O7GlobCwkFM8zobC+GMItO3PogQd3O2bu7ZLlgLjJ5PYms998O/vNOE5uIeKaAcDaP9uPK3e4gIguIu7YPgBw8wm2glnmbmJpgPS3YBPYysz6/kbEBgA8SinfpJSvANBGxFMppasTrINuqdUZIs4AgCaTCU2n08zCMCT2IeIzABwY0DVA+wqIeMWH2MIwnPu+n7y8vKSe5yXD4XDBPg06QsQDq97/QXNgxxosFUIkaZrS+fm5chxHlUol9fDwQPP5XI3H41jHPZt6L1naBQeAJ0TkwEUQBEREqlqtMiCxtdttxT4hhELEuY491WddA2jYcU1MZmahhsOhqlQqZADv7+9pNBopHbPQV29bknIci/KJDkyklCqKInV0dJQB7e7uUrFYzPa1Wo2SJOGEiQZ8XdGkoQoAf01mBvz8/CTP86hcLi8ZcoL393d+LGaZ6vi3le4xVBHxj7kuImZ1ms1mNBgMaH9/nw4PD2k8HrOMsnIgYqLjHzVY1lHZRtPdQ0RfFzqN45iUUvwIGRCD84OwsSallNmjsPjt7lppNQC4kVJSFEVxt9ulZrNJrVZL3d3d0e3treL99fU19fv9JIoiBp3pjloK3MhmR39LUsp/HNzpdOJ6vZ42m011eXmpGo2G4v3FxcWi1+ulHCOEOMv3vq3DLEMYhr8Y9Ovri/Tiay6IKDEOze7K9/21UZfv5UIYhs7Hx0dJCHEjhPC5fsaEEHEQBE9CiOMgCBwiWpmV26ZNwUqyp1+fJXWixW/Pxs3jy546RgL5Hs3NxMKPA3bTKNPAZnK7eVabwL4BmihRPobFAGMAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Download Icon",
                "title": "Download Icon",
                "src": "/static/2da93a0ed1ad4ae0fbbe44922f44625f/e68b9/download.png",
                "srcSet": ["/static/2da93a0ed1ad4ae0fbbe44922f44625f/e68b9/download.png 45w"],
                "sizes": "(max-width: 45px) 100vw, 45px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Downloads a csv file in external storage on the user's phone.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Delete all
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "42px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/f2aab9d984be8e81befc1af9ff5d985f/bfa22/deleteAll.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "97.61904761904762%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVQ4y42UMYhiQQxAXddir9/O2nZLC5sVQQRt3G3stbWwUMRKUNjm7MSDbeRs7AStrMVui6ssDm3UQkHUydcv6Pc7OfLJeONHjhuIM2Ym7yeTTDweHgDgFi/Lf+lvhmvzgUQIof5/E0I8k9CadLz3wHILvQPzsNGrEOKnEOI3AJgktGbd6z0bB6p/kWYhxBMA/BBCoJLNZuPIdru96vjMk27rANWCZ58QogcAZHTZbreWYRgXy7KkbdvycDg4OtrjMz22uTKuF83KDz5oAYA0TRMXi4Xs9/uy2+3K0WhEUPJO8hlaf7CtV4Xs5VADAHCkcMir1WqFxWJRplIpDAQC6Pf7MRaLYTqdlsPhEI/HI3mLbBNQUPp5ZHeLyjuC7nY7ORgMsFQqYblcxkqlgoVCAVutFq5WK6lFgmzrsDxaTam7s9frtURErFarmEgksF6vY61Wc4CRSEQlhcK2Oeyeujo97V9uYC6Xw06ng2rs93uMRqM4nU7RNE0d+KVKiEJVKR+4gfl83gnRsiynbGazmXOP8/mc4DpwwHlwgCopn1xjZzJGREkhttttxzvDMHC5XGI8HiewJG/pLNt8MuNvUgAgqYDsocxms7LRaODpdML1eo2TyQRDoZCcz+eSPTzzfSb1pHiY7gOAX/wyrPP5jM1mUwaDQfn29obJZBLD4bDMZDJyt9tdq4FtrsWtgCrTIQA4sacWeUHhjcdjOZlMHBFCSMMwFOzENleG/uy8vH7nZkBQCs02TdNisblckJvFu+ul3XYbyhLPLwDQpzerNwluDhfee9Ft/tXCVNYVOAsA31mypFP77kZ7t8mq9Lv7nLtvqnK7C9Oh+iEGP3IWfbz26h93w/4ANFu1e6fGB6cAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Delete All Icon",
                "title": "Delete All Icon",
                "src": "/static/f2aab9d984be8e81befc1af9ff5d985f/bfa22/deleteAll.png",
                "srcSet": ["/static/f2aab9d984be8e81befc1af9ff5d985f/bfa22/deleteAll.png 42w"],
                "sizes": "(max-width: 42px) 100vw, 42px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Deletes all records saved on the phone. There is no way to get records back after deleting.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Layout Change
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "39px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/21d420bcc78e5c60ddde17d6966e2222/00cdd/layout.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "87.17948717948718%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVQ4y4WUwUsbQRTGk1AolEKJB0kvrQUtGO1FTP6KHgrFHgKpV8FLA4JeRA/2oKU99FDEUy89WIpoEXMwkUqwRY+1B0G8lVZrkvftuq40yc4rbzpTJsHiwktmZ/f95nvzzdsYgJgTCfknIhk/BlAE8J2IfhORwt/rG4CXAO6Yd+M2X/IugyUBvCMiviJ+ARjphFpY3Dy4CeCTSWgBaAKIACgnWp7nNSwYwFNXkJWaMGW+MS81giBQtVqNa7WauiQ4CIKm53kCDQA8sFD9Y2AZgdXrdT4/P1cTExM8MDCgstksDw8P/4tsNqsGBwfV2NgYO0o/CENKd9W9supk5VQqpWKxGP8nVFdXlzo+PlaOynu6ZAOT+GKALXF0Z2eHt7a2eH19nTc2Nnh7e5uLxaK+l/lKpaLLlz02Kp+4e3jNHA+2JszOznI+n+f9/X0ulUqcy+V4bW2NDw8PeXR0lKenp/n09FTZsgEUXJfbgJ7nqe7ubl3e8vIyz8zM6PH4+LhWKeNkMsmmZAt85gIlPtuSBZhOp3WiqFpYWNDjqakpXa6M+/r61MnJiQAjkzdigQkDfGFNAcC9vb3alNXVVZ6fn9eQyclJLpfL2pSenh7XFB/AXWuKBQ7Zw3p2dhZlMhmduLKywnNzcxpYKBR4c3NTFlL9/f1crVb14kT03pgb72y71wbY2NvbU0tLS3xwcKB2d3fV4uKiEmePjo70fKVSafq+r9URUdo2SFvrAbgBoCTQMAxbURQ1Ra0c9CiKVBiGyvd9mW9cXFzY1suZQ51o+zjYBieiWwDeSsdIi5nuUdVqNZI5uZf5er3+A8Cjjtw2l902lIcPAXwE8FP2yUQI4CsRPSei27ZMl6GvDmjc2QKJlGn+IQD3AVzv/OS5sD/UMAIg1Z7HtgAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Layout Icon",
                "title": "Layout Icon",
                "src": "/static/21d420bcc78e5c60ddde17d6966e2222/00cdd/layout.png",
                "srcSet": ["/static/21d420bcc78e5c60ddde17d6966e2222/00cdd/layout.png 39w"],
                "sizes": "(max-width: 39px) 100vw, 39px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Toggles the time record history layout from either block grid style or spreadsheet grid style.`}</p>
        </blockquote>
      </li>
    </ul>
    <h3>{`Edit Indiviual Record`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/35116f5eda7ad89534a257e1c03ec31d/ec605/history_edit_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "195.00000000000003%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAnABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAQL/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABvU8uJ1QNPUXk2AAH/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQExEgMf/aAAgBAQABBQKhFKKFD6pRjb//xAAYEQACAwAAAAAAAAAAAAAAAAAAAhITIP/aAAgBAwEBPwGCla5//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAISIP/aAAgBAgEBPwG2LbP/xAAZEAACAwEAAAAAAAAAAAAAAAAQQQABMTD/2gAIAQEABj8C0bYQcfD/xAAdEAADAAEFAQAAAAAAAAAAAAAAARFRMUGBodEg/9oACAEBAAE/IVldDzPoT/QUr0I9UaduBW+Bp5FTfkkSnz//2gAMAwEAAgADAAAAEKMGAAAP/8QAFhEBAQEAAAAAAAAAAAAAAAAAYQAg/9oACAEDAQE/ECgz/8QAFhEBAQEAAAAAAAAAAAAAAAAAYQAg/9oACAECAQE/EGmz/8QAIRABAQABAwMFAAAAAAAAAAAAAREAITFBUXHBIGGBkbH/2gAIAQEAAT8QrX2d14ymaHWh/MK1DsHxjiG7wR+cW813XIqO/hMDesnCJlNCOgGcj6pmorrgCHp//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Edit Window",
            "title": "Edit Window",
            "src": "/static/35116f5eda7ad89534a257e1c03ec31d/09b79/history_edit_screen.jpg",
            "srcSet": ["/static/35116f5eda7ad89534a257e1c03ec31d/59e87/history_edit_screen.jpg 60w", "/static/35116f5eda7ad89534a257e1c03ec31d/e439a/history_edit_screen.jpg 120w", "/static/35116f5eda7ad89534a257e1c03ec31d/09b79/history_edit_screen.jpg 240w", "/static/35116f5eda7ad89534a257e1c03ec31d/158ba/history_edit_screen.jpg 360w", "/static/35116f5eda7ad89534a257e1c03ec31d/7cc5e/history_edit_screen.jpg 480w", "/static/35116f5eda7ad89534a257e1c03ec31d/ec605/history_edit_screen.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The edit window allows for the time record to be edited.
Tap on the list item to edit the specific item in the time record.
For times, a date option will be displayed for change, then a time option will be displayed for change.
When pressing `}<inlineCode parentName="p">{`OK`}</inlineCode>{`, the data/time will save the changes. When pressing `}<inlineCode parentName="p">{`CANCEL`}</inlineCode>{`, the data/time will not be changed.
For mileage, a decimal keyboard and a white popup box will display for change.
When pressing `}<inlineCode parentName="p">{`Change`}</inlineCode>{`, the mileage will save the change of the entered decimal number in the white box.
If the number is blank, then the mileage won't change.
When pressing `}<inlineCode parentName="p">{`Cancel`}</inlineCode>{`, the mileage will not change the mileage saved.
When a an item is edited, the bottom notification will display the save status.
After the time record has been finished being edited, press the back arrow on the top left of the screen to leave the edit window.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Call`}</inlineCode>{` `}<em parentName="li">{`Date/Time`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Enroute`}</inlineCode>{` `}<em parentName="li">{`Date/Time`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Scene`}</inlineCode>{` `}<em parentName="li">{`Date/Time`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Transport`}</inlineCode>{` `}<em parentName="li">{`Date/Time`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Destination`}</inlineCode>{` `}<em parentName="li">{`Date/Time`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Mileage`}</inlineCode>{` `}<em parentName="li">{`Decimal`}</em></li>
      <li parentName="ul"><inlineCode parentName="li">{`Notes`}</inlineCode>{` `}<em parentName="li">{`Text`}</em></li>
    </ul>
    <h2>{`Layouts`}</h2>
    <h4>{`Block Grid Layout`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c96d12320fd0683153f5514385ac46c9/ec605/history_screen.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "141.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAH2Z7BsCZqDUH//xAAYEAEBAAMAAAAAAAAAAAAAAAABAAIREv/aAAgBAQABBQJbqxdyNy2JoW6hm1Y3/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAGxAAAwACAwAAAAAAAAAAAAAAAAEREDFBcaH/2gAIAQEAAT8hLoQDj0U48GYKroshKyaJEf/aAAwDAQACAAMAAAAQ8w0w/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAAMAAgIDAAAAAAAAAAAAAQARIUFRMdFhcZH/2gAIAQEAAT8Qoym74uLs6/L6iKpW1HCWDioINn3DATm8lGVzlXFB13y+ozqVtQWtcfEe5+EBw7n/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "History Window",
            "title": "History Window",
            "src": "/static/c96d12320fd0683153f5514385ac46c9/09b79/history_screen.jpg",
            "srcSet": ["/static/c96d12320fd0683153f5514385ac46c9/59e87/history_screen.jpg 60w", "/static/c96d12320fd0683153f5514385ac46c9/e439a/history_screen.jpg 120w", "/static/c96d12320fd0683153f5514385ac46c9/09b79/history_screen.jpg 240w", "/static/c96d12320fd0683153f5514385ac46c9/158ba/history_screen.jpg 360w", "/static/c96d12320fd0683153f5514385ac46c9/7cc5e/history_screen.jpg 480w", "/static/c96d12320fd0683153f5514385ac46c9/ec605/history_screen.jpg 1125w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The block grid layout displays each time record with the times in a block.
There is the delete button and the edit button on the top corners of the time record block.`}</p>
    <h5>{`Indiviual Record Buttons`}</h5>
    <p>{`There are two icons on each corner of each time record that is pressable.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Edit
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "32px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0b690ddace5b41f87852c6258b272f46/103b3/edit.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClklEQVQ4y4VVPWsyQRDWRkinpX/JJmJho+KBVUgT1D9gGlsLS78aQZSAIJhgihCsQgptUsoVYmHh7ZwfUbhz52X2nb13zQdvMcze3Oyzz8zOzIYAIMQS1mshRBQALCHEAwDYAOCx2Gyz2Ofb3sAghNDrHAAsAACFEEpo/cP3gn1DvDesAcNsjABAzdhEjHwhhAQAU8jm6UNoDwBENDGFzifUGIg2eQSkv3ktv9g89kXeq3A0WI6ZkcOZ1o7jSBY0RTNm/7MBmlOAABAVQix0mKR3u52UUqLv++h5ntLn81np3W6HRiQ6NWSjnEYJ0GKDR4z2+72cz+fYaDSw0+lgq9XCZrOJ7XYbu90uLhaLANTIr86pFeIyoJ8+hXQ8HuXz8zNms1m0LCuQfD6PNzc3OJvN8HA4BOEzW5/1Q4jrTIegHLbbbVAmrusGZUNr+qcrgfOqWZLNDhk5UJdwPB5xNBphIpHAVCqF19fXSieTSUyn0/j+/q4YbjYbigY/Pz8lh67yeQFIQgxs28bX11cl0+kUX15e8PHxESeTCS6XS5VDkmq1iuPxWBIop8ALQtaA5Pjx8YG9Xg8HgwH2+318e3tTt306nYgRrlYrdUClUsH7+3vJLP+G/NOlPD09YSaTwUKhoHS9Xr9ov2KxiPF4HIfDoYrIcZyLS7GMegpYEpP9fh9ouhDaVCqVMBaLqRu3bZt8VdcwoPWtsAlcd4hmRBdAh5TLZby6usLb21uyqYMdx9FghBENWk/nUbeezqkGJk2s7u7u5Hq9pgYg29komVzQyyw1A1SHb9anKititd1uae0ZPf1vOBjDMWKAIk8T3xgG0nXdi/HFB16Or/8N2K/akF8H7K9PAJUB1Zb5BJCN//34BPwBHANaB71c9pEAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Edit Icon",
                "title": "Edit Icon",
                "src": "/static/0b690ddace5b41f87852c6258b272f46/103b3/edit.png",
                "srcSet": ["/static/0b690ddace5b41f87852c6258b272f46/103b3/edit.png 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Allows for the record to be edited. An edit window will display to edit the record.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Delete
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "32px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/fe2b266271adfdda3dfe1c86637b9c30/103b3/delete.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQUlEQVQ4y7WVPa7iQAzH85pXcxhAQEOFkDgAHRJ34Ag0UCBAHICKCr0z8NUgSMEFED0isYskEALjlWftvMC+7XaRRsyY+BeP/7ZxENGR9aF7AMghYhsAvhDxhIiJrJPY2vLMH76pAQB030LEIyISANjF+x/OR3nWEd8PBX6I8RMRR+oAAAkAPBDRAIDRb7El+gLx+dTALF3eoDAjMCNO5g2qtkTP4ms5CmtlYE/e+75vMtD0ym/2ZwbaskBEzAHAUfLCkVEQBOZ2u73kzPd9u9geRRHJ9VMfYeQY2Nac8dvjODbz+ZwGgwHFcZzC2InPk8mEZrOZ3WeiTSSgtiNlwA4PdgzD0Gy3WyqVStTv9+l6vVoYRzYejymfz9NisbB2ATLoIYwvR+rM5o8XQ+/3O+33e6pUKtTtdu2ZYYVCgdbrtT17nkdZ0YRxcjIlkKrIUL4SQxuNBjWbTarX67RcLq2dYRmhssDkBajqMfByuRB/OELHcajT6ZAx5gX2N+DpPUI+cyTD4ZCq1aoVoVarWbgKlYG+XllF4cRqnYVhSKPRiIrFIq1WKxspX79cLlOv12PhVHmtxxdR0rLxPC8tG3bebDZWgPP5bCPb7XZWqOl0au0/lo0WthRnEgQBua5rXNd9EUDr8HA4WDAXv/pIQL8LW1tP88HtxJ0QRRGXkOYmLXC285IcPvXaaetpUwNAOhx8308UpuqraNKCRlpOn/keDtnxxT9k5l4iQpmMknZ8yTRStdnne3z9jwH7T/8CfgEe2Ge3lTfeBAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Delete Icon",
                "title": "Delete Icon",
                "src": "/static/fe2b266271adfdda3dfe1c86637b9c30/103b3/delete.png",
                "srcSet": ["/static/fe2b266271adfdda3dfe1c86637b9c30/103b3/delete.png 32w"],
                "sizes": "(max-width: 32px) 100vw, 32px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`Deletes the one record selected. Once deleted, there is no way to get the record back.
A confirmation prompt will display before deleting the record.`}</p>
        </blockquote>
      </li>
    </ul>
    <h5>{`Spreadsheet Grid Layout`}</h5>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "240px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/18f3f180e3f6425e25a057df8d0b99b7/939c5/history_sheet.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "148.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHuKUaCBEI1JD//xAAZEAADAQEBAAAAAAAAAAAAAAAAAREhECD/2gAIAQEAAQUCpS+NN5UVFR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAXEAEBAQEAAAAAAAAAAAAAAAAAMUEg/9oACAEBAAY/Ata1VXiIj//EACEQAAECBAcAAAAAAAAAAAAAAAAhMQEQEXFBUWGBsdHw/9oACAEBAAE/IfVBlmQ7oV5FUa0uwZgwuhBC4QsIg//aAAwDAQACAAMAAAAQEAkw/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHxAAAgEEAgMAAAAAAAAAAAAAAAEhETFBYVGRcaHB/9oACAEBAAE/EKKqMg1beMOR5YVgkiN8EUfBXI2ohbhCs2fJo7Buu3sVOwdNxsQa0P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "History Window",
            "title": "History Window",
            "src": "/static/18f3f180e3f6425e25a057df8d0b99b7/09b79/history_sheet.jpg",
            "srcSet": ["/static/18f3f180e3f6425e25a057df8d0b99b7/59e87/history_sheet.jpg 60w", "/static/18f3f180e3f6425e25a057df8d0b99b7/e439a/history_sheet.jpg 120w", "/static/18f3f180e3f6425e25a057df8d0b99b7/09b79/history_sheet.jpg 240w", "/static/18f3f180e3f6425e25a057df8d0b99b7/158ba/history_sheet.jpg 360w", "/static/18f3f180e3f6425e25a057df8d0b99b7/939c5/history_sheet.jpg 436w"],
            "sizes": "(max-width: 240px) 100vw, 240px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The spreadsheet grid layout compacts the time records to make it easier to see all the times.
Tap on the horizontal list of times to edit the times on that record.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      